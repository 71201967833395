.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background: #282c34;
}

.timeline ul {
  margin: 0;
  margin-top: 20px;
  list-style: none;
  position: relative;
  padding: 1px 100px;
  color: #fff;
  font-size: 13px;
  text-align: left;
  left: 25px;
}

.timeline ul:before {
  content: "";
  width: 1px;
  top: 5px;
  bottom: 5px;
  position: absolute;
  border-left: 2px dashed #fff;
}

.timeline ul li {
  position: relative;
  margin-left: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 14px;
  border-radius: 6px;
  width: 100px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.08);
}

.timeline ul li:not(:first-child) {
  margin-top: 15px;
}

.timeline ul li > div.line {
  width: 2px;
  height: 70%;
  background: #fff;
  left: -40px;
  top: 5px;
  position: absolute;
}

.timeline ul li span:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  background: #fb673c;
  left: 60px;
  top: 15%;
}

.timeline ul li > div {
  margin-left: 10px;
}

.timeline div .title {
  font-weight: 600;
  font-size: 12px;
}

.timeline div.number {
  height: 80%;
}

.timeline div.number span {
  position: absolute;
  font-size: 12px;
  left: -95px;
  font-weight: bold;
}

.timeline div.number span:first-child {
  top: 0;
}

.timeline div.number span:last-child {
  top: 70%;
}

.timeline div.markertitle {
  font-weight: 600;
  font-size: 12px;
  color: #fb673c;
}

.timeline div.markernumber {
  height: 80%;
}

.timeline div.markernumber span {
  position: absolute;
  font-size: 12px;
  left: -95px;
  font-weight: bold;
}

.timeline div.markernumber span:first-child {
  top: 15px;
}

button {
  color: #fb673c;
  background: none;
  border: none;
  padding: 0px;
  margin: 5px;
  cursor: pointer;
}

label {
  color: #fb673c;
}

.inputrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputrow label {
  flex: 2;
}

.inputrow input.name {
  width: 50%;
}

.inputrow button.delete {
  flex: 0;
}

.buttonrow {
  margin-top: 10px;
}

.controlrow {
  margin-top: 10px;
  line-height: 34px;
}

.shortnumber {
  width: 10%;
  height: 24px;
}

.slider {
  -webkit-appearance: none;
  width: 60%;
  height: 15px;
  margin-top: 10px;
  border-radius: 7px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.9;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fb673c;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fb673c;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 14px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .toggle {
  background-color: #fb673c;
}

input:focus + .toggle {
  box-shadow: 0 0 1px #fb673c;
}

input:checked + .toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

@keyframes bg {
  100% {
      background-size:  0 3px,
                        3px 0,
                        0 3px,
                        3px 0;
  }
  75% {
      background-size:  100% 3px,
                        3px 0,
                        0 3px,
                        3px 0;
  }
  50% {
      background-size:  100% 3px,
                        3px 100%,
                        0 3px,
                        3px 0;
  }
  25% {
      background-size:  100% 3px,
                        3px 100%,
                        100% 3px,
                        3px 0;
  }
  0% {
      background-size:  100% 3px,
                        3px 100%,
                        100% 3px,
                        3px 100%;
  }
}

.reveal {  
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #fb673c 100%, #fb673c 100%),
                    linear-gradient(to bottom, #fb673c 100%, #fb673c 100%),
                    linear-gradient(to right, #fb673c 100%, #fb673c 100%),
                    linear-gradient(to bottom, #fb673c 100%, #fb673c 100%);
  background-size:  100% 3px,
                    3px 100%,
                    100% 3px,
                    3px 100%;
  background-position:  0 0,
                        100% 0,
                        100% 100%,
                        0 100%;
  animation: bg 5s linear;
  animation-play-state: running;
}


